import React, { useState, useEffect, useRef } from 'react';
import { Box, IconButton, Button, Typography, Modal, Stack } from "@mui/material";

import { Notifications } from '@mui/icons-material';
import useAxiosPrivate from "../utils/useAxiosPrivate";
import { useAuth } from "../utils/AuthProvider";
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '40%',
  overflow: 'scroll',
  overflowX: "hidden",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Dropdown = ({ notifications, onSelect }) => {

  const convertTime = (timeString) => {
    const time = moment(timeString, 'HH:mm:ss');
    return time.format('h:mm A');
  };

  return (
    <Box>
      {notifications ? (
        notifications.slice(0, 5).map((notification) => (
          <Stack key={notification.eventNotificationId} onClick={() => onSelect(notification)} paddingBottom={0.5}>
            <Stack 
            borderRadius={3}
            border={0.5}
            padding={1}
            >
              <Typography>Fire Rover Activated: {notification.eventType}</Typography>
              <Typography fontSize={14}>Unit: {notification.productName}</Typography>
              <Typography fontSize={14}>Site: {notification.siteName}</Typography>
              <Typography fontSize={14}>Time: {notification.dateOfEvent}, {convertTime(notification.timeOnly)}</Typography>
            </Stack>
          </Stack>
        ))
      ) : (
        <Typography>No Notifications</Typography>
      )}
    </Box>
  );
};

const NotificationsButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const buttonRef = useRef(null);
  const [buttonY, setButtonY] = useState(null);
  const [buttonX, setButtonX] = useState(null);
  const [buttonHeight, setButtonHeight] = useState(null);
  const [openedNotification, setOpenedNotification] = useState(null);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const servicePhone = "248-200-3730"

  const navigate = useNavigate();
  const { cookies } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const [eventNotificationRows, setEventNotificationRows] = useState([]);

  const handleOpenModal = () => {
    fetchData();
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenNotification = () => {
    setIsNotificationOpen(true);
  };

  const handleCloseNotification = () => {
    setIsNotificationOpen(false);
  }

  const handleNotificationSelect = (value) => {
    setOpenedNotification(value);
    handleOpenNotification();
  };

  const handleShowMore = () => {
    setIsModalOpen(false);
    navigate(`/notifications`)
  }

  const fetchData = () => {
    const controller = new AbortController();

    const getEventNotifications = async () => {
      try {
        if (cookies.user) {
          const response = await axiosPrivate.get('/api/User/eventNotifications/' + cookies.user, {
            signal: controller.signal
          })
  
          setEventNotificationRows(response.data.slice().reverse());
        }
      } catch (err) {
        console.log(err);
      }
    }

    getEventNotifications();

    return () => {
      controller.abort();
    }
  }

  useEffect(() => {
    if (buttonRef.current) {
      setButtonX(buttonRef.current.getBoundingClientRect().x);
      setButtonY(buttonRef.current.getBoundingClientRect().y);
      setButtonHeight(buttonRef.current.getBoundingClientRect().height);
    }
    // eslint-disable-next-line 
  }, [buttonRef]);

  const convertTime = (timeString) => {
    const time = moment(timeString, 'HH:mm:ss'); // Parse the time string
    return time.format('h:mm A');
  };

  return (
    <>
      <IconButton ref={buttonRef} onClick={handleOpenModal}>
        <Notifications />
      </IconButton>
      <Modal 
        open={isModalOpen} 
        onClose={handleCloseModal}
      >
        <Box
          sx={{
            position: "relative",
            top: buttonY + buttonHeight,
            left: buttonX - 7*buttonHeight,
            maxHeight: "80%",
            width: "20%",
            overflowX: "hidden",
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 1,
            borderRadius: 3,
          }}
          >
          {eventNotificationRows ? 
            <>
              <Dropdown notifications={eventNotificationRows} onSelect={handleNotificationSelect} />
              <Stack sx={{alignItems: "center", padding: 1}}>
                <Button variant="contained" onClick={handleShowMore}>
                  Show More
                </Button>
              </Stack>
            </>
          : 
            <Stack sx={{alignItems: "center"}}>
              <Typography>No Notifications</Typography>
            </Stack>
          }
        </Box>
      </Modal>
      <Modal 
        open={isNotificationOpen} 
        onClose={handleCloseNotification}
      >
        <Box
          sx={style}
        >
          <Box style={{paddingHorizontal: "0.5%", backgroundColor: 'rgba(59, 59, 59, 0.15)'}}>
            <Box>
              <Typography>Unit: {openedNotification ? openedNotification.productName : null}</Typography>
              <Typography>Site: {openedNotification ? openedNotification.siteName : null}</Typography>
              <Typography>Time: {openedNotification ? openedNotification.dateOfEvent : null}, {convertTime(openedNotification ? openedNotification.timeOnly : null)}</Typography>
            </Box>
            <Box>
              <img
                src={openedNotification ? openedNotification.fileLink : null}
                alt={''}
                width={"100%"}
              />
            </Box>
            <Box>
              <Typography>Account Manger: {openedNotification ? openedNotification.accountManager : null}</Typography>
              <Typography>Account Manager Phone: {openedNotification ? openedNotification.accountManagerPhone : null}</Typography>
              <Typography>Central Station Phone: {openedNotification ? openedNotification.centralStationPhone : null}</Typography>
              <Typography>Service Department Phone: {servicePhone}</Typography>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default NotificationsButton;